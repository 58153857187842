/* eslint-disable @typescript-eslint/no-unused-vars */
import { ROUTES_NAMES } from '../routes'
import { COMPLIANCE_STAGES } from '../constants'
import { useBusiness } from '~/src/composables/apis/business'
import { RouteLocationNormalized } from '#vue-router'

const getCompliancePage = (route: RouteLocationNormalized, stage?: number) => {
  const stages = Object.entries(COMPLIANCE_STAGES)
  const [name] = stages.find(([, value]) => value.stageValue === stage) || []

  return {
    routeName: name || ROUTES_NAMES.compliances,
    isCompliancePage: route.name?.toString().includes(ROUTES_NAMES.compliances),
  }
}

// TODO: deprecate after releade of new sign up
// export default defineNuxtRouteMiddleware((to) => {
//   const { business } = useBusiness()
//   const { isCompliancePage, routeName } = getCompliancePage(to, business.value.stage)

//   // When we are on compliance page, and business is live redirect user to transactions page
//   if (isCompliancePage && business.value.live)
//     return navigateTo({
//       name: ROUTES_NAMES.transactions,
//     })

//   // // When we are on compliance page, and business is not live, do not redirect (prevents infinite routing loop)
//   if (isCompliancePage && !business.value.live) {
//     return undefined
//   }

//   // if business is on every other route, and is not live, redirect to compliance
//   if (!business.value.live)
//     return navigateTo({
//       name: routeName,
//     })
// })

export default defineNuxtRouteMiddleware((to) => {
  const { business } = useBusiness()
  const { isCompliancePage, routeName } = getCompliancePage(to, business.value.stage)

  // When we are on compliance page, route to correct stage
  // if (isCompliancePage)
  //   return to.name === routeName
  //     ? undefined
  //     : navigateTo({
  //         name: routeName,
  //       })
})
